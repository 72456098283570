<template>
    <div class="bg-white box-shadow border-r px-20 pb-20">
        <div class="d-flex-center border-bottom">
            <h4 class="mr-auto color-333">{{ $t('wallet.positions') }}</h4>
            <label class="mr-5">{{ $t('wallet.umb') }} : </label>
            <span class="color-p font-bold font-20" v-text="usdt"></span>
            <label class="ml-3">USDT</label>
            <el-button type="primary" size="mini" class="ml-10" icon="el-icon-refresh" plain @click="fatchData()">{{ $t('refresh') }}</el-button>
        </div>
        <el-row :gutter="10" type="flex" justify="center" align="middle" class="color-gray py-15 border-bottom font-12">
            <el-col :span='3'>{{ $t('market.symbol') }}</el-col>
            <el-col :span='3'>{{ $t('usdsm.long') }}/{{ $t('usdsm.short') }}</el-col>
            <el-col :span='3'>{{ $t('usdsm.margin_mode') }}</el-col>
            <el-col :span='3'>{{ $t('usdsm.size') }}</el-col>
            <el-col :span='3'>{{ $t('usdsm.pnl') }}</el-col>
            <el-col :span='3'>{{ $t('usdsm.margin') }}</el-col>
            <el-col :span='2'>{{ $t('usdsm.entry_price') }}</el-col>
            <el-col :span='2'>{{ $t('usdsm.mark_price') }}</el-col>
            <el-col :span='2' class="text-right">{{ $t('usdsm.liq_price') }}</el-col>
        </el-row>
        <div v-if="positions.length" class="" v-loading="loading">
            <el-row v-for="(item, index) in positions" :key="index" :gutter="10" type="flex" justify="center" align="middle" class="py-15 border-bottom font-12">
                <el-col :span='3'>
                    <span>{{ item.pair }} / USDT</span>
                    <span class="ml-5 color-gray">Perp</span>
                </el-col>
                <el-col :span='3'>
                    <el-tag v-if="item.way" type="danger" size="small" effect="plain" hit>Short</el-tag>
                    <el-tag v-else type="success" size="small" effect="plain" hit>Long</el-tag>
                </el-col>
                <el-col :span='3'>
                    <span v-if="item.margin_mode">Isolated</span>
                    <span v-else>Cross</span>
                    <span class="ml-5">{{ item.leverage }}X</span>
                </el-col>
                <el-col :span='3'>{{ item.size }}</el-col>
                <el-col :span='3'>{{ item.pnl }}</el-col>
                <el-col :span='3'>{{ item.margin }}</el-col>
                <el-col :span='2'>{{ item.entry_price }}</el-col>
                <el-col :span='2'>{{ item.mark_price }}</el-col>
                <el-col :span='2' class="text-right">{{ item.liq_price }}</el-col>
            </el-row>
        </div>
        <el-empty v-else :image-size="100" description="No Data"></el-empty>
    </div>
</template>

<script>
import coinLogo from '@/assets/coin/logo'
export default {
    data() {
        return {
            coinLogo,
            loading: false,
            usdt: '--',
            positions: [
                // {
                //     id: 1,
                //     way: 1,
                //     pair: 'ETH',
                //     margin_mode: 0,
                //     leverage: 5,
                //     pnl: '-1000.00',
                //     roi: '-79.24',
                //     size: '4.000',
                //     margin: '2342.02',
                //     margin_ratio: '0.90',
                //     entry_price: '3392.35',
                //     mark_price: '2932.44',
                //     liq_price: '1643.36'
                // }
            ]
        }
    },
    created: function() {
        this.fatchData()
    },
    methods: {
        fatchData: async function () {
            this.loading = true
            try {
                const { data } = await this.$request.get('wallet/futures')
                this.usdt = data.usdt
                this.positions = data.positions
                
            } catch (error) {
                //
            }
            this.loading = false
        },
    }
}
</script>
<style lang="scss" scoped>
//
</style>